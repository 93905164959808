<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex">
            <vs-button v-if="!selectable && $can('create_stk')" color="primary" type="border" icon-pack="feather" icon="icon-plus" class="px-3 mr-3" @click="modalAdd.active = true">Tambah</vs-button>
            <vs-button v-if="!selectable" color="primary" :type="isEmptyFilter ? 'flat' : 'filled'" icon-pack="feather" icon="icon-filter" class="px-3" @click="modalFilter.active = true">Filter</vs-button>
          </div>
        </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap" v-if="!selectable">Aksi</vs-th>
          <vs-th class="whitespace-no-wrap">Nama Proyek</vs-th>
          <vs-th class="whitespace-no-wrap">No.SPK</vs-th>
          <vs-th class="whitespace-no-wrap">No.STK</vs-th>
          <vs-th class="whitespace-no-wrap">STK Ke</vs-th>
          <vs-th class="whitespace-no-wrap">Tgl. STK</vs-th>
          <vs-th class="whitespace-no-wrap">Status Approval</vs-th>
          <vs-th class="whitespace-no-wrap">Approval Progress</vs-th>
          <vs-th class="whitespace-no-wrap">Awal Pelaksanaan</vs-th>
          <vs-th class="whitespace-no-wrap">Akhir Pelaksanaan</vs-th>
          <vs-th class="whitespace-no-wrap">Masa Pemeliharaan</vs-th>
          <vs-th class="whitespace-no-wrap">Akhir Tgl. Pemeliharaan</vs-th>
          <vs-th class="whitespace-no-wrap">Progress Real</vs-th>
          <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          <vs-th class="whitespace-no-wrap">File</vs-th>
          <vs-th class="whitespace-no-wrap">Gambar</vs-th>
          <vs-th class="whitespace-no-wrap">Created By</vs-th>
          <vs-th class="whitespace-no-wrap">Created At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td v-if="!selectable">
              <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer" v-if="$canAny(['update_stk', 'delete_stk'])">
                <vs-button class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                <vs-dropdown-menu>
                  <vs-dropdown-item class="p-1" @click="onClickPrint(item)" :disabled="item.status_approval !== 'DITERIMA'">Print</vs-dropdown-item>
                  <vs-dropdown-item class="p-1" @click="showModalEdit(item)" :disabled="item.status_approval === 'DITERIMA'"><span class="whitespace-no-wrap" v-if="$can('update_stk')">Edit</span></vs-dropdown-item>
                  <vs-dropdown-item class="p-1" @click="confirmDelete(item.id)"><span class="whitespace-no-wrap text-danger" v-if="$can('delete_stk')">Hapus</span></vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.kode_proyek }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_spk }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_stk }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.stk_ke }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.tgl_stk }}</vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex items-center">
                <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="'bg-' + item.status_approval_color">
                  <span class="text-xs">{{ item.status_approval }}</span>
                </div>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <span v-if="item.status_approval" class="text-xs underline cursor-pointer" @click="showModalApproval(item)">Lihat</span>
              <span v-else class="text-sm">-</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.awal_pelaksanaan }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.akhir_pelaksanaan }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.masa_pemeliharaan }} Hari</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.akhir_tgl_pemeliharaan }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.progress_real ? `${item.progress_real}%` : '-' }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
            <vs-td class="whitespace-no-wrap">
              <span v-if="item.file_url.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item)">Lihat Files</span>
              <span v-else class="text-sm">-</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <img v-if="item.gambar" :src="item.gambar_url[0]" @click="showImages(item.gambar_url)" class="w-8 h-auto cursor-pointer" alt="photos"/>
              <span v-else>-</span>
            </vs-td>
            <vs-td>{{ item.created_by }}</vs-td>
            <vs-td>{{ item.created_at }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>

    </vx-card>

    <!--modals-->
    <StkFilter v-if="!selectable" :isActive.sync="modalFilter.active" @filter="onFilter"/>
    <StkAdd v-if="!selectable" :isActive.sync="modalAdd.active" @success="getData"/>
    <StkEdit v-if="!selectable" :isActive.sync="modalEdit.active" @success="getData" :item="modalEdit.item"/>
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import StkRepository from '@/repositories/proyek/stk-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import printoutMixin from '@/utilities/mixins/printout-mixin'
import _ from 'lodash'

export default {
  name: 'Stk',
  props: {
    selectable: { default: false, type: Boolean },
    externalFilter: { default: null, type: Object }
  },
  mixins: [printoutMixin],
  watch: {
    externalFilter (newVal, oldVal) {
      if (newVal && !_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  components: {
    ImageViewer,
    PagingLimit,
    FilesViewer: () => import('@/views/components/files-viewer/FilesViewer'),
    StkFilter: () => import('@/views/pages/proyek/stk/StkFilter'),
    StkAdd: () => import('@/views/pages/proyek/stk/StkAdd'),
    StkEdit: () => import('@/views/pages/proyek/stk/StkEdit'),
    ModalApprovalProgress: () => import('@/views/components/approval-progress/ModalApprovalProgress'),
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv')
  },
  data () {
    return {
      filter: {},
      modalFilter: {
        active: false
      },
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        item: {}
      },
      modalFiles: {
        filesUrl: [],
        active: false
      },
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      },
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    },
    isEmptyFilter () {
      return _.isEmpty(this.filter)
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      params.filter = JSON.stringify({
        only_authorized_user: 1,
        ...this.filter
      })
      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      StkRepository.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    delete (id) {
      StkRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            this.notifyErrorUsingDialog(errors)
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
    },

    onFilter (filterData) {
      this.filter = filterData
      this.getData()
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    showModalEdit (item) {
      this.modalEdit.item = item
      this.modalEdit.active = true
    },

    showModalApproval (item) {
      this.modalApproval.type = 'STK'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    },

    showFiles (item) {
      this.modalFiles.filesUrl = item.file_url
      this.modalFiles.active = true
    },

    showImages (images) {
      const items = _.cloneDeep(images)
      this.$refs.viewer.show(items)
    },

    printStkKe1 (idStk) {
      this.showPrintOutFile('BASTK_1', { id: idStk })
    },

    printStkKe2 (idStk) {
      this.showPrintOutFile('BASTK_2', { id: idStk })
    },

    onClickPrint (item) {
      if (item.stk_ke === 1) {
        this.printStkKe1(item.id)
      } else {
        this.printStkKe2(item.id)
      }
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
